import React from 'react';

function App() {
  return (
    <div className="container">
      <div>Welkom bij</div>
      <div className='header'>No-Nonsens</div>
      <img className='responsive' src={'NoNonsens.png'} alt={'No Nonsens Dieren'} />
      <div>Eet-, drink- & muziekcafé</div>
      <div className='about'>
      <div className='text'>Een bruisende ontmoetingsplek waar je kunt genieten van heerlijke gerechten, verfrissende drankjes en live muziek in een gezellige sfeer.</div> 
      <div className='text'>Dit café biedt een gevarieerd menu met zowel klassieke als moderne gerechten, bereid met verse, lokale ingrediënten.</div>  
      <div className='text'>De drankkaart is even indrukwekkend, met een breed scala aan bieren, wijnen en cocktails om uit te kiezen. </div> 
      <div className='text'>Wat dit café echt bijzonder maakt, is de muzikale omlijsting: van akoestische optredens tot levendige bands, er is altijd iets te beleven.</div> 
      <div className='text'>Het is de ideale plek om te ontspannen met vrienden, een romantische avond te beleven of gewoon te genieten van een goede maaltijd met fantastische muziek op de achtergrond.</div>
      </div>
      <div className='footer'></div>
    </div>
  );
}

export default App;
